import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App/index';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';

Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: config.sentry.sampleRate,
});

const store = createStore(reducer);
const history = createBrowserHistory();

const app = (
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>
);

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) history.replace(path);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
