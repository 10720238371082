import React from 'react';

const SignIn = React.lazy(() => import('./pages/_auth/signin'));
const ForgotPassword = React.lazy(() => import('./pages/_auth/reset-password'));
const ResetPassword = React.lazy(() => import('./pages/_auth/new-password'));
const UnlockAccount = React.lazy(() => import('./pages/_auth/unlock-account'));
const VerifyLink = React.lazy(() => import('./pages/_auth/verify'));

const route = [
    { path: '/auth/signin', exact: true, name: 'Sign In', component: SignIn },
    { path: '/auth/forgot-password', exact: true, name: 'Forgot Password', component: ForgotPassword },
    { path: '/auth/new-password/:link', exact: true, name: 'Set Password', component: ResetPassword },
    { path: '/auth/unlock/:link', exact: true, name: 'Unlock Account', component: UnlockAccount },
    { path: '/auth/email-verify/:link', exact: true, name: 'Verify Link', component: VerifyLink }
];

export default route;